( function() {

    var wistia = document.querySelectorAll( ".wistia" );

    

    for (var i = 0; i < wistia.length; i++) {
    
    var source = "https://embed-ssl.wistia.com/deliveries/"+ wistia[i].dataset.thumb +".jpg";
    
    var image = new Image();
    image.src = source;
    image.addEventListener( "load", function() {
    wistia[ i ].appendChild( image );
    }( i ) );
    
    wistia[i].addEventListener( "click", function() {




    var iframe = document.createElement( "iframe" );
    
        iframe.setAttribute( "frameborder", "0" );
        iframe.setAttribute( "allowfullscreen", "" );
        iframe.setAttribute( "mozallowfullscreen", "" );
        iframe.setAttribute( "webkitallowfullscreen", "" );
        iframe.setAttribute( "oallowfullscreen", "" );
        iframe.setAttribute( "msallowfullscreen", "" );
        iframe.setAttribute( "src", "//fast.wistia.net/embed/iframe/"+ this.dataset.embed +"?videoFoam=true" );
        
        this.innerHTML = "";
        this.appendChild( iframe );
    } );    
    };
    
    } )();